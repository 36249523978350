<template>
    <v-menu transition="slide-y-transition" origin="center" offset-y :close-on-content-click="false" rounded="lg">
        <template v-slot:activator="{ on, value }">
            <v-hover v-slot:default="{ hover }">
                <v-row align="center" v-on="on" class="cursor-pointer transition-cubic mx-0 pb-1" :style="!hover ? {borderBottom: '1px solid var(--v-cell4-base)'}:{borderBottom: '1px solid var(--v-black2white-base)'}">
                    <v-col class="pa-0">
                        <v-row class="mx-0" align="center" no-gutters>
                            <v-col v-for="(item, key, i) in val" :key="i" cols="auto" v-if="!item.read_only" class="pr-1">
                                <v-chip
                                        small
                                        class="cursor-pointer elevation-2"
                                        close
                                        color="primary"
                                        @click:close="deleteItem(key)"
                                >
                                    {{ item.label }}
                                </v-chip>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto" class="py-0 pl-0" style="padding-right: 2px">
                        <v-btn :class="value ? 'transition-rotate':''" fab depressed x-small color="cell" class="elevation-2" style="max-width: 24px; max-height: 24px">
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-hover>
        </template>
        <v-list style="max-height: 500px; overflow-y: auto; overflow-x: hidden">
            <v-list-item v-for="(item, key, i) in items" :key="i" link @click="selectItem(key)" v-if="!item.read_only">
                <v-list-item-action class="mr-4">
                    <v-icon size="20" :color="val[key] ? 'primary':''">{{ val[key] ? 'mdi-checkbox-marked':'mdi-checkbox-blank-outline' }}</v-icon>
                </v-list-item-action>
                <v-list-item-content :class="val[key] ? 'labelprimary--text':''" class="paragraph">
                    {{ item.label }}
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    export default {
        name: "HeadersSelect",
        props: ['value', 'items'],
        components: {
            Button: () => import('@/components/ui/Button.vue')
        },
        computed: {
            val: {
                get(){
                    return this.value
                },
                set(val){
                    this.$emit('input', val)
                }
            }
        },
        methods: {
            deleteItem(key){
                this.$delete(this.val, key)
                this.$emit('change', this.val)
            },
            selectItem(key){
                if(this.val[key]){
                    this.$delete(this.val, key)
                }
                else {
                    this.$set(this.val, key, this.items[key])
                }

                this.$emit('change', this.val)
            }
        }
    }
</script>
